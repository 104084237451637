import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit'
import thunk from 'redux-thunk';
import rootReducer from './index';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

const createNoopStorage = () => {
   return {
      getItem(_key) {
         return Promise.resolve(null);
      },
      setItem(_key, value) {
         return Promise.resolve(value);
      },
      removeItem(_key) {
         return Promise.resolve();
      },
   };
};
const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

export default function createStore(initialState = {}, options = {}) {
  const persistConfig = {
    key: `AppFront_${process.env.GATSBY_BUSINESS_ID}`,
    version: 1,
    storage,
    // stateReconciler: autoMergeLevel2
  };
  
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const middlewares = [thunk];

  const enhancers = [applyMiddleware(...middlewares)];

  const composeEnhancers =
    //process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
        })
      : compose;
  /* eslint-enable */

  const store = configureStore({
    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
      {
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }
    ).concat(thunk),
    // enhancers: composeEnhancers,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  });

  const persistor = persistStore(store, null, () => {
    store.dispatch({ type: 'REDUX_STORAGE_LOAD' })
  });

  return  { store, persistor };
}
