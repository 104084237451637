import * as ACTION_TYPE from "./constants";
import _ from "lodash";
import { setExecutePayment } from "../order/actions";
import { LOGIN_TYPES } from "../../components/LoginView";
import { setSignUpOnPaymentInProgress } from "../user/actions";

export const setKeyboardOpen = (keyboardOpen) => {
  return { type: ACTION_TYPE.SET_KEYBOARD_STATUS, payload: { keyboardOpen } };
};

export const setGroupAppModeParams = (params) => {
  return {
    type: ACTION_TYPE.SET_GROUP_APP_MODE_PARAMS,
    payload: { groupReferralUrl: _.get(params, "groupReferralUrl") },
  };
};

export const toggleCheckoutView = () => {
  return { type: ACTION_TYPE.TOGGLE_CHECKOUT_VIEW };
};

export const openCheckoutView = () => {
  return { type: ACTION_TYPE.OPEN_CHECKOUT_VIEW };
};

export const closeCheckoutView = () => {
  return { type: ACTION_TYPE.CLOSE_CHECKOUT_VIEW };
};

export const openAuthView = (loginType) => (dispatch, getState) => {
  const { app } = getState();

  return !app.isMobile
    ? dispatch({ type: ACTION_TYPE.OPEN_AUTH_MODAL, payload: { loginType } })
    : dispatch(openMobileAuthView(loginType));
};

//closes signup/login and verify code
export const closeAuthModal = (shouldStopSignUp) => (dispatch, getState) => {
  const { app } = getState();
  const isFromPayment = app.loginType === LOGIN_TYPES.PAYMENT;

  if (shouldStopSignUp && isFromPayment) {
    dispatch(setSignUpOnPaymentInProgress(false));
    dispatch(setExecutePayment(false));
  }

  dispatch({ type: ACTION_TYPE.CLOSE_AUTH_MODAL });
}

//closes auth view after privacy consent(if present) and triggers payment if needed
export const closeAuthView = (shouldStopSignUp) => (dispatch, getState) => {
  const { app } = getState();
  const isFromPayment = app.loginType === LOGIN_TYPES.PAYMENT;

  !shouldStopSignUp && isFromPayment && dispatch(setExecutePayment(isFromPayment));
  app.isMobile && dispatch(closeMobileAuthView());
  dispatch({ type: ACTION_TYPE.CLOSE_AUTH_VIEW });
};

export const openMobileAuthView = (loginType) => {
  window.scrollTo(0, 0);
  return { type: ACTION_TYPE.OPEN_MOBILE_AUTH_VIEW, payload: { loginType } };
};

export const closeMobileAuthView = () => {
  return { type: ACTION_TYPE.CLOSE_MOBILE_AUTH_VIEW };
};

export const setCameraPermissionsAndroid = ({ granted, error }) => {
  if (error) {
    console.log("error updating camera permissions: ", error);
  }
  return {
    type: ACTION_TYPE.SET_CAMERA_PERMISSIONS_ANDROID,
    payload: { granted },
  };
};

export const setKioskLocationId = (kioskLocationId) => ({
  type: ACTION_TYPE.SET_KIOSK_LOCATION_ID,
  payload: { kioskLocationId },
});

export const setInStoreSignupBranchId = (branchId) => ({
  type: ACTION_TYPE.SET_IN_STORE_SIGNUP_BRANCHID,
  payload: { branchId },
});

export const setGoogleLoaded = (google) => ({
  type: ACTION_TYPE.GOOGLE_LOADED,
  payload: { google },
});

export const setEditorAppStyles = (appStyles) => ({
  type: ACTION_TYPE.SET_EDITOR_APP_STYLES,
  payload: { appStyles },
});

export const resetEditorAppStyles = () => ({
  type: ACTION_TYPE.RESET_EDITOR_APP_STYLES,
});

export const setEditorPromotions = (promotions) => ({
  type: ACTION_TYPE.SET_EDITOR_PROMOTIONS,
  payload: { promotions },
});

export const resetsetEditorPromotions = () => ({
  type: ACTION_TYPE.RESET_EDITOR_PROMOTIONS,
});

export const setIsMobileView = (isMobile) => ({
  type: ACTION_TYPE.SET_IS_MOBILE_VIEW,
  payload: { isMobile }
});
