import {
    Box,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select
} from "@mui/material";
import { makeStyles } from "../../AppContainer/mui-theme";
import CheckCircle from "@mui/icons-material/CheckCircle";
import cn from "classnames";
import MobileDetect from "mobile-detect";
import React, { forwardRef, useEffect, useMemo, useState } from "react";

import ErrorXIcon from "../../icons/ErrorX.svg";

import * as styles from "./index.module.scss";

const useStyles = makeStyles()((theme, { inputValidColor, inputBorderRadius, inputOnCardBackgroundColor }) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: inputValidColor,
      },
    },
    "& .MuiFormHelperText-root.Mui-error": {
      margin: 0,
    },
  },
  input: {
    borderRadius: inputBorderRadius,
    height: "52px",
    backgroundColor: inputOnCardBackgroundColor,
  },
  mobileMultipleSelect: {
    height: "auto",
  },
  left: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  middle: { borderRadius: 0 },
  right: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  selected: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: inputValidColor,
      },
    },
    "& label": {
      color: inputValidColor,
    },
  },
  error: { backgroundColor: "#fae7e7" },
  notSelected: {
    "& .MuiSelect-selectMenu": { color: "#757575" },
  },
}));

function SelectInput({
  groupPosition,
  onChange,
  options,
  value,
  placeholder,
  label,
  style,
  appStyles,
  noCheckmark,
  isError,
  errorMessage,
  multiple,
  customIconAdornment,
  ...props
}, ref) {
  const { classes } = useStyles(appStyles);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const md = new MobileDetect(window.navigator.userAgent);
    setIsMobile(Boolean(md && md.mobile()));
  }, []);

  const [selectValue, setSelectValue] = useState(multiple ? [""] : "");
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (!value || (!value.length && multiple)) {
      setSelectValue(multiple ? [""] : "");
      setIsSelected(false);
      return;
    }

    if (!multiple) {
      setSelectValue(value);
      setIsSelected(true);
      return;
    }

    setSelectValue(value.filter(Boolean));
  }, [value, multiple]);

  const optionItems = options.length
    ? options.map(({ label, value }, indx) =>
        //TODO uncomment code below when webview issue with select is fixed

        // isMobile ? (
        //   <option
        //     key={label + value + indx}
        //     value={value}
        //     dir={appStyles.rtl ? "rtl" : "ltr"}
        //   >
        //     {label}
        //   </option>
        // ) : (
          <MenuItem
            key={label + value + indx}
            value={value}
            dir={appStyles.rtl ? "rtl" : "ltr"}
          >
            {label}
          </MenuItem>
        // )
      )
    : null;

  const showCheckmarkIcon = useMemo(() => !noCheckmark && isSelected, [
    noCheckmark,
    isSelected,
  ]);

  const iconAdornment = useMemo(
    () => (
      <InputAdornment
        position="start"
        // sx={{
        //   "& svg": {
        //     "& path": { fill: "#808080" },
        //   },
        // }}
      >
        {showCheckmarkIcon ? (
          <CheckCircle style={{ color: appStyles.inputValidColor }} />
        ) : (
          customIconAdornment || null
        )}
      </InputAdornment>
    ),
    [appStyles.inputValidColor, showCheckmarkIcon, customIconAdornment]
  );

  const errorMessageComponent = useMemo(
    () =>
      isError &&
      errorMessage && (
        <FormHelperText
          className={styles.errorAnimation}
          error={isError}
          id="component-error-text"
        >
          <ErrorXIcon className="noselect" style={{ marginRight: 4 }} />
          {errorMessage}
        </FormHelperText>
      ),
    [isError, errorMessage]
  );

  const handleChangeDesktop = (event) => {
    setIsSelected(true);
    const value = multiple
      ? event.target.value.filter(Boolean)
      : event.target.value;
    onChange(value);
  };

  const desktopContent = (
    <FormControl
      fullWidth
      className={cn(classes.root, {
        [classes.selected]: isSelected,
        [classes.notSelected]: !isSelected,
      })}
    >
      {label && <InputLabel id="select-label">{label}</InputLabel>}
      <Select
        {...props}
        id="select"
        labelId="select-label"
        label={label}
        value={selectValue}
        dir={appStyles.rtl ? "rtl" : "ltr"}
        displayEmpty
        multiple={multiple}
        onChange={handleChangeDesktop}
        startAdornment={iconAdornment}
        className={cn(
          classes.input,
          { [classes.error]: isError },
          classes[groupPosition]
        )}
        aria-describedby="component-error-text"
        ref={ref}
      >
        {placeholder && (
          <MenuItem value="" disabled dir={appStyles.rtl ? "rtl" : "ltr"}>
            {placeholder}
          </MenuItem>
        )}
        {optionItems}
      </Select>
      {errorMessageComponent}
    </FormControl>
  );

  const handleChangeMobile = (event) => {
    let { value } = event.target;

    if (multiple) {
      const { options } = event.target;
      value = [];
      for (let i = 0, l = options.length; i < l; i += 1) {
        if (options[i].selected) {
          value.push(options[i].value);
        }
      }
    }

    setIsSelected(true);
    onChange(multiple ? value.filter(Boolean) : value);
  };

  const mobileContent = (
    <FormControl
      fullWidth
      className={cn(classes.root, {
        [classes.selected]: isSelected,
        [classes.notSelected]: !isSelected,
      })}
    >
      {label && (
        <InputLabel shrink={multiple} htmlFor="select-label">
          {label}
        </InputLabel>
      )}
      <Select
        {...props}
        native
        label={label}
        multiple={multiple}
        value={selectValue}
        dir={appStyles.rtl ? "rtl" : "ltr"}
        onChange={handleChangeMobile}
        startAdornment={iconAdornment}
        className={cn(
          classes.input,
          { [classes.mobileMultipleSelect]: multiple },
          { [classes.error]: isError },
          classes[groupPosition]
        )}
        inputProps={{
          id: "select-label",
          size: 0,
        }}
        aria-describedby="component-error-text"
      >
        <option
          aria-label="None"
          disabled
          value=""
          dir={appStyles.rtl ? "rtl" : "ltr"}
        >
          {placeholder}
        </option>

        {optionItems}
      </Select>
      {errorMessageComponent}
    </FormControl>
  );

  //TODO replace with code below when webview issue with select is fixed
  return <Box sx={style}>{desktopContent}</Box>;
  // return <Box sx={style}>{isMobile ? mobileContent : desktopContent}</Box>;
}

export default forwardRef(SelectInput);
