import classnames from "classnames";
import _ from "lodash";
import React from "react";
import * as styles from "./CardRadioButtonsGroup.module.scss";

const getInitialCheckedIndex = children => {
  let checkedIndex;

  for (let i = 0; i < children.length; i++) {
    if (!children[i].props.disabled) {
      checkedIndex = i;
      break;
    }
  }

  return checkedIndex;
};

class RadioGroup extends React.Component {
  constructor(props) {
    super(props);
    const { children, value } = props;
    const index = _.findIndex(children, c => c.props.value === value);
    let checkedIndex;
    if (value === undefined || value === null)
      // This is the case where it is not specified
      checkedIndex = -1;
    else {
      if (index > -1 && !children[index].props.disabled) checkedIndex = index;
      else checkedIndex = getInitialCheckedIndex(children);
    }
    this.state = { checkedIndex: checkedIndex };
  }

  get value() {
    const { checkedIndex } = this.state;
    const { children } = this.props;

    const child = children.find(c => c.props.index === checkedIndex);
    return (child && child.props.value) || "";
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  componentDidUpdate(prevProps) {
    const children = this.props.children;

    const index = children.findIndex(
      c => c.props.value === this.props.value && !c.props.disabled,
    );

    if (index === -1 && index !== this.state.checkedIndex) {
      return this.setState({ checkedIndex: -1 });
    }

    if (index !== -1 && index !== this.state.checkedIndex) {
      this.setState({ checkedIndex: index });
    }
  }

  onChange = index => {
    const { onChange, children } = this.props;
    const child = children[index];
    if (!child) return;

    this.setState(
      { checkedIndex: index },
      () => onChange && onChange(child.props.value || ""),
    );
  };

  renderChild = (child, index, checked) => {
    const { children, horizontal, appStyles: { rtl } = {} } = this.props;
    return React.cloneElement(child, {
      rtl,
      horizontal,
      index,
      checked,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      tabindex: index === 0 ? "0" : "-1",
      ...child.props,
    });
  };

  render() {
    const { checkedIndex } = this.state;
    const { horizontal, children, wrap, ...props } = this.props;
    const style = horizontal ? { display: "inline-flex", width: "100%" } : {};
    return (
      <div
        style={style}
        role="radiogroup"
        {...props}
        className={classnames(
          styles.CardRadioButtonsGroup,
          wrap && styles.Wrap,
        )}
      >
        {_.map(children, (c, i) => this.renderChild(c, i, i === checkedIndex))}
      </div>
    );
  }
}

class RadioButton extends React.Component {
  getStyles = () => {
    const {
      horizontal,
      last,
      rtl,
      padding = 10,
      rootColor = "currentColor",
      pointColor = "#8CB9FD",
      disabled,
      disabledColor = "#e1e1e1",
      label,
    } = this.props;

    const styleHorizontal = {
      root: {
        display: "flex",
        flexDirection: "column-reverse",
        alignItems: "center",
      },
    };

    const style = {
      root: {
        transition: "all 0.2s ease-in-out",
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? disabledColor : rootColor,
        borderColor: disabled ? disabledColor : rootColor,
        padding: padding,
        marginBottom: horizontal ? 0 : padding,
        marginRight: horizontal && !last ? (!rtl ? padding / 2 : 0) : 0,
        marginLeft: horizontal && !last ? (rtl ? padding / 2 : 0) : 0,
      },
      label: {
        transition: "all 0.2s ease-in-out",
        color: pointColor,
        borderStyle: "none",
        padding: padding / 2,
        marginBottom: horizontal ? 0 : padding / 2,
        marginRight: horizontal && !last ? (!rtl ? padding / 4 : 0) : 0,
        marginLeft: horizontal && !last ? (rtl ? padding / 4 : 0) : 0,
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: pointColor,
        color: pointColor,
        fontWeight: "bold",
      },
    };

    return horizontal ? _.merge(style, styleHorizontal) : style;
  };

  onClick = () => {
    const { onChange, checked, index, disabled } = this.props;
    !disabled && onChange && onChange(index);
  };

  render() {
    const {
      checked,
      iconSize,
      iconInnerSize,
      rootColor,
      pointColor,
      children,
      disabled,
      disabledColor,
      outerBorderColor,
      padding = 10,
      rtl,
      label,
      tabindex,
    } = this.props;
    const style = this.getStyles();
    const buttonStyle = _.assign({}, style.root, checked ? style.checked : {});
    const labelStyle = _.assign({}, style.root, style.label);

    return (
      <div
        role="radio"
        aria-checked={Boolean(checked)}
        tabindex={tabindex}
        style={buttonStyle}
        onClick={this.onClick}
        className={styles.CardRadioButtonOptionWrapper}
      >
        {children ? (
          <div style={{ display: "inline-flex", width: "100%" }}>
            <RadioIcon
              size={iconSize}
              innerSize={iconInnerSize}
              checked={checked}
              rootColor={rootColor}
              pointColor={pointColor}
              outerBorderColor={outerBorderColor}
              disabled={disabled}
              disabledColor={disabledColor}
              marginRight={padding}
              rtl={rtl}
            />
            <div
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
              }}
            >
              {children}
            </div>
          </div>
        ) : (
          <RadioIcon
            size={iconSize}
            innerSize={iconInnerSize}
            checked={checked}
            rootColor={rootColor}
            pointColor={pointColor}
            outerBorderColor={outerBorderColor}
            disabled={disabled}
            disabledColor={disabledColor}
            marginRight={padding}
            rtl={rtl}
          />
        )}

        {label ? (
          <div style={labelStyle}>
            <div>{label}</div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

class RadioIcon extends React.Component {
  getStyles = () => {
    const {
      size = 10,
      innerSize,
      rootColor = "#9E9E9E",
      pointColor = "#8CB9FD",
      outerBorderColor = "#777",
      disabled,
      disabledColor = "#e1e1e1",
      marginRight = 10,
      backgroundColor = "#FFF",
      rtl,
    } = this.props;

    return {
      root: {
        transition: "all 0.2s ease-in-out",
        width: size,
        height: size,
        padding: 4,
        backgroundColor,
        borderWidth: 1,
        borderRadius: "50%",
        borderStyle: "solid",
        borderColor: disabled ? disabledColor : outerBorderColor,
        ...(!rtl && { marginRight }),
        ...(rtl && { marginLeft: marginRight }),
        flexShrink: 0,
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: outerBorderColor,
      },
      inner: {
        transition: "all 0.2s ease-in-out",
        width: innerSize || 10,
        height: innerSize || 10,
        borderRadius: "50%",
        background: pointColor,
      },
    };
  };

  render() {
    const { checked } = this.props;
    const style = this.getStyles();
    const iconStyle = _.assign(style.root, checked ? style.checked : {});
    return (
      <div style={iconStyle}>{checked && <div style={style.inner} />}</div>
    );
  }
}

export const CardRadioButtonsGroup = props => <RadioGroup {...props} />;

export const CardRadioButtonOption = props => <RadioButton {...props} />;
