import _ from "lodash";
import * as ACTION_TYPE from "./constants";
import * as APP_ACTION_TYPE from "../app/constants";
import { SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION } from "../order/constants";
import { loaderState, updaterState } from "./../reducerStateUpdaters";
import reduceByCouponsChecked from "../reduceByCouponsChecked";
import getCouponBatchesSelectionInitialState from "../getCouponBatchesSelectionInitialState";

const {
  initUpdaterState,
  setUpdaterSending,
  setUpdaterSent,
  setUpdaterError,
} = updaterState;
const {
  initLoader,
  setLoading,
  setDataLoaded,
  setError,
  resetErrorAndLoading,
} = loaderState;

const donateGiftInitialState = {
  selectedGiftId: null,
  selectedCharityId: null,
  selectedCharityDonationTotal: null,
  ...initUpdaterState(),
};

const shareGiftInitialState = {
  giftId: null,
  contactDetails: null,
  ...initUpdaterState(),
};

const sortPaymentMethods = (paymentMethods, defaultPaymentMethodToken) => {
  return defaultPaymentMethodToken
    ? _.orderBy(paymentMethods, (obj) =>
      obj.token === defaultPaymentMethodToken ? 0 : 1,
    )
    : paymentMethods;
};

export const initialState = {
  userDetails: initLoader(),
  gifts: [],
  contacts: [],
  loadingContacts: false,
  loadingContactsError: null,
  loadingGifts: false,
  loadingLatestOrders: false,
  loadingLatestOrdersError: null,
  latestOrders: [],
  errorLoadingGifts: null,
  paymentMethods: initLoader(),
  lastPhoneSent: null,
  privacyConsentDetails: initLoader(),
  history: initLoader(),
  loyaltyProfile: initLoader(),
  purchasesById: {},
  loginState: initUpdaterState(),
  verificationCodeState: initUpdaterState(),
  signupState: initUpdaterState(),
  consentState: initUpdaterState(),
  saveProfileState: initUpdaterState(),
  donateGiftState: donateGiftInitialState,
  shareGiftState: shareGiftInitialState,
  autoGeoLocation: true,
  userLocatedAddress: null,
  profileSignUpFlowMode: false,
  registerPushNotifications: initUpdaterState({
    isNewsApproved: false,
    isPurchaseApproved: false,
    showedPushApprovalPopUp: false
  }),
  defaultPaymentMethodToken: null,
  addPaymentMethodsState: initUpdaterState(),
  uniqueIdentifier: null,
  sendFeedbackState: initUpdaterState(),
  tempLocation: initLoader(),
  chargeCardDetails: initLoader(),
  rechargeCard: initUpdaterState(),
  removingPaymentMethods: initUpdaterState(),
  authMode: null,
  showVerifyView: false,
  showPrivacyConsent: false,
  showPrivacyConsentInfoModal: false,
  shouldSignUpOnPayment: true,
  isSingUpInProgress: false,
  // TODO: move to another reducer - purchase events
  currentPurchaseEvent: null,
  respondToPurchaseEventFromPOS: initUpdaterState(),
  shouldPollPendingPurchaseEvents: false,
  shouldPollStatusPurchaseEvent: false,
  purchaseEventBatchResponse: initLoader(),
  amountOfGiftsUnseen: 0,
  getGiftsUnseenAmountError: null,
  giftCardState: initUpdaterState(),
  ...getCouponBatchesSelectionInitialState(),
  waitingOrders: initLoader(),
  deleteAccount: initUpdaterState(),
  subscription: initLoader()
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REDUX_STORAGE_LOAD":
      return {
        ...state,
        loyaltyProfile: resetErrorAndLoading(state.loyaltyProfile),
        loginState: initUpdaterState(),
        signupState: initUpdaterState(state.signupState),
        shareGiftState: {
          ...state.shareGiftState,
          sending: false,
          sent: false,
          error: null,
        },
        loadingGifts: false,
        loadingLatestOrders: false,
        verificationCodeState: initUpdaterState(),
        saveProfileState: initUpdaterState(),
        addPaymentMethodsState: initUpdaterState(),
        tempLocation: resetErrorAndLoading(state.tempLocation),
        history: resetErrorAndLoading(state.history),
        userDetails: resetErrorAndLoading(state.userDetails),
        chargeCardDetails: resetErrorAndLoading(state.chargeCardDetails),
        registerPushNotifications: initUpdaterState(
          state.registerPushNotifications,
        ),
        rechargeCard: initUpdaterState(),
        removingPaymentMethods: initUpdaterState(),
        respondToPurchaseEventFromPOS: initUpdaterState(),
        purchaseEventBatchResponse: resetErrorAndLoading(
          state.purchaseEventBatchResponse,
        ),
        giftCardState: initUpdaterState(state.giftCardState),
        waitingOrders: initLoader(),
        deleteAccount: initUpdaterState(),
      };

    case ACTION_TYPE.OPEN_AUTH_LOGIN:
      return {
        ...state,
        authMode: "login",
        showVerifyView: false,
        showPrivacyConsent: false,
      };
    case ACTION_TYPE.OPEN_AUTH_SIGNUP:
      return {
        ...state,
        authMode: "signup",
        showVerifyView: false,
        showPrivacyConsent: false,
      };
    case APP_ACTION_TYPE.CLOSE_AUTH_VIEW:
      return {
        ...state,
        authMode: null,
        showPrivacyConsent: false,
      };
    case APP_ACTION_TYPE.CLOSE_AUTH_MODAL:
      return {
        ...state,
        authMode: null,
        showVerifyView: false,
      };
    case ACTION_TYPE.RESET_AUTH:
      return {
        ...state,
        authMode: null,
        showVerifyView: false,
        showPrivacyConsent: false,
        signupState: initUpdaterState(),
        loginState: initUpdaterState(),
      };
    case ACTION_TYPE.SHOW_VERIFY_CODE:
      return { ...state, showVerifyView: true, showPrivacyConsent: false };
    case ACTION_TYPE.CLOSE_PRIVACY_CONSENT:
      return action.payload.shouldLogOut ? initialState : { ...state, showPrivacyConsent: false };
    case ACTION_TYPE.CLOSE_VERIFY_CODE:
      return {
        ...state,
        showVerifyView: false,
        signupState: initUpdaterState(),
        loginState: initUpdaterState(),
        consentState: initUpdaterState(),
      };
    case ACTION_TYPE.SHOW_PRIVACY_CONSENT:
      return { ...state, showPrivacyConsent: true, showVerifyView: false };
    case ACTION_TYPE.SHOW_PRIVACY_CONSENT_INFO:
      return { ...state, showPrivacyConsentInfoModal: action.payload.showInfoModal };
    case ACTION_TYPE.LOGIN.STARTED:
      return {
        ...state,
        lastPhoneSent: action.payload.phoneNumber,
        verificationCodeState: initUpdaterState(),
        loginState: {
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.LOGIN.SUCCESS:
      return {
        ...state,
        loginState: {
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.LOGIN.FAILED:
      return {
        ...state,
        loginState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPE.LOGIN.RESET:
      return {
        ...state,
        loginState: initUpdaterState(),
        verificationCodeState: initUpdaterState(),
      };
    case ACTION_TYPE.SET_SHOULD_SIGN_UP_ON_PAYMENT:
    case ACTION_TYPE.SIGN_UP_ON_PAYMENT: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case ACTION_TYPE.SIGNUP.STARTED:
      return {
        ...state,
        verificationCodeState: initUpdaterState(),
        lastPhoneSent: action.payload.phoneNumber,
        signupState: {
          ...action.payload,
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.SIGNUP.SUCCESS:
      return {
        ...state,
        signupState: {
          ...state.signupState,
          hasSignedUpPreviously: action.payload.hasSignedUpPreviously,
          signupGiftCardDetails: _.get(action.payload, "signupGiftCardDetails"),
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.SIGNUP.FAILED:
      return {
        ...state,
        signupState: {
          ...state.signupState,
          sending: false,
          sent: false,
          error: action.payload.error,
        },
        isSingUpInProgress: false,
      };
    case ACTION_TYPE.SIGNUP.RESET:
      return {
        ...state,
        signupState: initUpdaterState(),
      };

    case ACTION_TYPE.SIGNUP_RESET_KEEP_DATA:
      return {
        ...state,
        signupState: {
          ...state.signupState,
          sending: false,
          sent: false,
          error: null,
        },
      };

    case ACTION_TYPE.VERIFY_CODE.STARTED:
      return {
        ...state,
        verificationCodeState: {
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.VERIFY_CODE.SUCCESS:
      return {
        ...state,
        ..._.omit(action.payload.user, "userDetails"),
        userDetails: {
          ...state.userDetails,
          data: action.payload.user.userDetails,
        },
        loggedIn: true,
        paymentMethods: initLoader(),
        verificationCodeState: {
          sending: false,
          sent: true,
          error: null,
        },
        shouldPollPendingPurchaseEvents: true,
      };
    case ACTION_TYPE.VERIFY_CODE.FAILED:
      return {
        ...state,
        verificationCodeState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPE.VERIFY_CODE.RESET:
      return {
        ...state,
        verificationCodeState: initUpdaterState(),
      };
    case ACTION_TYPE.LOGOUT:
      return { ...initialState };

    case ACTION_TYPE.LOAD_PAYMENT_METHODS.STARTED:
      return {
        ...state,
        paymentMethods: setLoading(state.paymentMethods),
      };
    case ACTION_TYPE.LOAD_PAYMENT_METHODS.SUCCESS:
      const filteredPaymentMethods = _.filter(
        sortPaymentMethods(
          action.payload.paymentMethods,
          state.defaultPaymentMethodToken,
        ),
        (paymentMethod) => paymentMethod,
      );
      return {
        ...state,
        paymentMethods: setDataLoaded(
          state.paymentMethods,
          filteredPaymentMethods,
        ),
      };
    case ACTION_TYPE.LOAD_PAYMENT_METHODS.FAILED:
      return {
        ...state,
        paymentMethods: setError(state.paymentMethods, action.payload.error),
      };
    case ACTION_TYPE.SET_DEFAULT_PAYMENT_METHOD:
      return { ...state, defaultPaymentMethodToken: action.payload.token };
    case ACTION_TYPE.LOAD_GIFTS.STARTED:
      return {
        ...state,
        loadingGifts: true,
        errorLoadingGifts: null,
      };
    case ACTION_TYPE.LOAD_GIFTS.SUCCESS:
      return {
        ...state,
        loadingGifts: false,
        gifts: action.payload.gifts,
        errorLoadingGifts: null,
      };
    case ACTION_TYPE.LOAD_GIFTS.FAILED:
      return {
        ...state,
        gifts: [],
        loadingGifts: false,
        errorLoadingGifts: action.payload.error,
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD.RESET:
      return {
        ...state,
        addPaymentMethodsState: initUpdaterState(),
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD.STARTED:
      return {
        ...state,
        addPaymentMethodsState: setUpdaterSending(state.addPaymentMethodsState),
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD.SUCCESS:
      if (state.loggedIn) {
        return {
          ...state,
          addPaymentMethodsState: setUpdaterSent(
            state.addPaymentMethodsState,
            action.payload.addPaymentResponse.token,
          ),
          paymentMethods: setDataLoaded(
            state.paymentMethods,
            action.payload.shouldSavePaymentMethod
              ? state.paymentMethods.data
                ? _.concat(
                  _.filter(
                    state.paymentMethods.data,
                    (obj) =>
                      obj.displayString !==
                      action.payload.addPaymentResponse.displayString,
                  ),
                  [action.payload.addPaymentResponse],
                )
                : [action.payload.addPaymentResponse]
              : [],
          ),
        };
      } else {
        return state;
      }
    case ACTION_TYPE.ADD_PAYMENT_METHOD.FAILED:
      return {
        ...state,
        addPaymentMethodsState: setUpdaterError(
          state.addPaymentMethodsState,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD_AND_CHECKOUT.STARTED:
      return {
        ...state,
        addPaymentMethodsState: setUpdaterSending(state.addPaymentMethodsState),
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD_AND_CHECKOUT.SUCCESS:
      return {
        ...state,
        addPaymentMethodsState: setUpdaterSent(
          state.addPaymentMethodsState,
          action.payload.addPaymentResponse.token,
        ),
      };
    case ACTION_TYPE.ADD_PAYMENT_METHOD_AND_CHECKOUT.FAILED:
      return {
        ...state,
        addPaymentMethodsState: setUpdaterError(
          state.addPaymentMethodsState,
          action.payload.error,
        ),
      };
    // This will make an optimistic update and removes all payment methods.
    case ACTION_TYPE.REMOVE_PAYMENT_METHOD.STARTED:
      const paymentMethodToRemove = action.payload.paymentMethodTokenToRemove
        ? _.find(
          state.paymentMethods.data,
          (obj) => obj.token === action.payload.paymentMethodTokenToRemove,
        )
        : state.paymentMethods.data
          ? state.paymentMethods.data[0]
          : {};
      return {
        ...state,
        paymentMethodToRemove,
        paymentMethods: setDataLoaded(
          state.paymentMethods,
          _.filter(
            state.paymentMethods.data,
            (obj) => obj.token !== action.payload.paymentMethodTokenToRemove,
          ),
        ),
        removingPaymentMethods: setUpdaterSending(state.removingPaymentMethods),
      };
    case ACTION_TYPE.REMOVE_PAYMENT_METHOD.SUCCESS:
      return {
        ...state,
        removingPaymentMethods: setUpdaterSent(state.removingPaymentMethods),
        paymentMethodToRemove: null,
      };
    case ACTION_TYPE.REMOVE_PAYMENT_METHOD.FAILED:
      return {
        ...state,
        paymentMethods: setDataLoaded(
          state.paymentMethods,
          _.concat(state.paymentMethods.data, state.paymentMethodToRemove),
        ),
        paymentMethodToRemove: null,
        removingPaymentMethods: setUpdaterError(
          state.removingPaymentMethods,
          action.payload.error,
        ),
      };

    case ACTION_TYPE.SAVE_PROFILE.STARTED:
      return {
        ...state,
        saveProfileState: {
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.SAVE_PROFILE.SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            ...{
              name: action.payload.userDetails.name,
              email: action.payload.userDetails.email,
              preferences: action.payload.userDetails.preferences,
              birthday: action.payload.userDetails.birthday,
            },
          },
        },
        saveProfileState: {
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.SAVE_PROFILE.FAILED:
      return {
        ...state,
        saveProfileState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };

    case ACTION_TYPE.SAVE_PROFILE.RESET:
      return {
        ...state,
        saveProfileState: initUpdaterState(),
      };

    case ACTION_TYPE.SEND_FEEDBACK.STARTED:
      return {
        ...state,
        sendFeedbackState: {
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.SEND_FEEDBACK.SUCCESS:
      return {
        ...state,
        sendFeedbackState: {
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.SEND_FEEDBACK.FAILED:
      return {
        ...state,
        sendFeedbackState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };

    case ACTION_TYPE.SEND_FEEDBACK.RESET:
      return {
        ...state,
        sendFeedbackState: initUpdaterState(),
      };
    case ACTION_TYPE.SELECT_GIFT_FOR_DONATION:
      return {
        ...state,
        donateGiftState: {
          selectedGiftId: action.payload.giftId,
          sending: false,
          sent: false,
          error: null,
        },
      };

    case ACTION_TYPE.DONATE_GIFT.STARTED:
      return {
        ...state,
        donateGiftState: {
          selectedCharityId: action.payload.charityId,
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.DONATE_GIFT.SUCCESS:
      return {
        ...state,
        donateGiftState: {
          selectedCharityId: action.payload.charityId,
          selectedCharityDonationTotal: action.payload.donationTotal,
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.DONATE_GIFT.FAILED:
      return {
        ...state,
        donateGiftState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };
    case ACTION_TYPE.DONATE_GIFT.RESET:
      return {
        ...state,
        donateGiftState: donateGiftInitialState,
      };

    case ACTION_TYPE.SELECT_GIFT_FOR_SHARE:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          giftId: action.payload.giftId,
        },
      };

    case ACTION_TYPE.SELECT_CONTACT_FOR_GIFT_SHARE:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          contactDetails: action.payload.contactDetails,
        },
      };

    case ACTION_TYPE.SELECT_CONTACT_FOR_GIFT_CARD:
      return {
        ...state,
        giftCardState: {
          ...state.giftCardState,
          contactDetails: action.payload.contactDetails,
        },
      };

    case ACTION_TYPE.SHARE_GIFT.STARTED:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          sending: true,
          sent: false,
          error: null,
        },
      };

    case ACTION_TYPE.SHARE_GIFT.SUCCESS:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          sending: false,
          sent: true,
          error: null,
        },
      };

    case ACTION_TYPE.SHARE_GIFT.FAILED:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };

    case ACTION_TYPE.SHARE_GIFT.RESET:
      return {
        ...state,
        shareGiftState: shareGiftInitialState,
      };

    case ACTION_TYPE.DISABLE_AUTO_GEOLOCATION:
      return {
        ...state,
        autoGeoLocation: false,
      };
    case ACTION_TYPE.ENABLE_AUTO_GEOLOCATION:
      return {
        ...state,
        autoGeoLocation: true,
      };
    case ACTION_TYPE.LOAD_CONTACTS.STARTED:
      return {
        ...state,
        loadingContacts: true,
        loadingContactsError: null,
      };
    case ACTION_TYPE.LOAD_CONTACTS.SUCCESS:
      return {
        ...state,
        contacts: _.map(action.payload.contacts, (contact) => {
          const phoneNumber = _.get(contact, "phoneNumbers[0].stringValue");
          return { ..._.omit(contact, "phoneNumbers"), phoneNumber };
        }),
        loadingContacts: false,
        loadingContactsError: null,
      };
    case ACTION_TYPE.LOAD_CONTACTS.FAILED:
      return {
        ...state,
        loadingContacts: false,
        loadingContactsError: action.payload.error,
      };
    case ACTION_TYPE.LOAD_LATEST_ORDERS.STARTED:
      return {
        ...state,
        loadingLatestOrders: true,
        loadingLatestOrdersError: null,
        latestOrders: [],
      };
    case ACTION_TYPE.LOAD_LATEST_ORDERS.SUCCESS:
      return {
        ...state,
        loadingLatestOrders: false,
        loadingLatestOrdersError: null,
        latestOrders: action.payload.latestOrders,
      };
    case ACTION_TYPE.LOAD_LATEST_ORDERS.FAILED:
      return {
        ...state,
        loadingLatestOrders: false,
        loadingLatestOrdersError: action.payload.error,
        latestOrders: [],
      };
    case ACTION_TYPE.LOAD_HISTORY.STARTED:
      return {
        ...state,
        history: setLoading(state.history),
      };
    case ACTION_TYPE.LOAD_HISTORY.SUCCESS:
      return {
        ...state,
        history: setDataLoaded(state.history, action.payload.data),
      };
    case ACTION_TYPE.LOAD_HISTORY.FAILED:
      return {
        ...state,
        history: setError(state.history, action.payload.error),
      };
    case ACTION_TYPE.LOAD_PURCHASE.STARTED:
      return {
        ...state,
        purchasesById: {
          ...state.purchasesById,
          [action.payload.id]: setLoading(
            state.purchasesById[action.payload.id],
          ),
        },
      };
    case ACTION_TYPE.LOAD_PURCHASE.SUCCESS:
      return {
        ...state,
        purchasesById: {
          ...state.purchasesById,
          [action.payload.id]: setDataLoaded(
            state.purchasesById[action.payload.id],
            action.payload.data,
          ),
        },
      };
    case ACTION_TYPE.LOAD_PURCHASE.FAILED:
      return {
        ...state,
        purchasesById: {
          ...state.purchasesById,
          [action.payload.id]: setError(
            state.purchasesById[action.payload.id],
            action.payload.error,
          ),
        },
      };

    case ACTION_TYPE.SAVE_PROFILE_PICTURE.STARTED:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          data: {
            ...state.userDetails.data,
            avatarImage: { url: action.payload.imageUrl },
          },
        },
        saveProfileState: {
          sending: true,
          sent: false,
          error: null,
        },
      };
    case ACTION_TYPE.SAVE_PROFILE_PICTURE.SUCCESS:
      return {
        ...state,
        saveProfileState: {
          sending: false,
          sent: true,
          error: null,
        },
      };
    case ACTION_TYPE.SAVE_PROFILE_PICTURE.FAILED:
      return {
        ...state,
        saveProfileState: {
          sending: false,
          sent: false,
          error: action.payload.error,
        },
      };

    case ACTION_TYPE.SAVE_USER_LOCATION:
      return {
        ...state,
        userLocatedAddress: action.payload.userAddress,
      };

    case ACTION_TYPE.SET_PROFILE_SIGNUP_FLOW_MODE:
      return { ...state, profileSignUpFlowMode: true };

    case ACTION_TYPE.SET_PROFILE_REGULAR_MODE:
      return { ...state, profileSignUpFlowMode: false };

    case ACTION_TYPE.MARK_PUSH_POPUP_SHOWN:
      return { ...state, registerPushNotifications: { ...state.registerPushNotifications, showedPushApprovalPopUp: true }}
    case ACTION_TYPE.LOAD_USER_DETAILS.STARTED:
      return {
        ...state,
        ...action.payload.forcedUserParams,
        userDetails: setLoading(state.userDetails),
      };
    case ACTION_TYPE.LOAD_USER_DETAILS.SUCCESS:
      return {
        ...state,
        userDetails: setDataLoaded(
          state.userDetails,
          action.payload.userDetails,
        ),
        ...(action.payload.openChargeCardInstance && {
          openChargeCardInstance: action.payload.openChargeCardInstance,
        }),
        loggedIn: !_.isEmpty(action.payload.userDetails),
      };
    case ACTION_TYPE.LOAD_USER_DETAILS.FAILED:
      return {
        ...state,
        userDetails: setError(state.userDetails, action.payload.error),
      };

    case ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.STARTED:
      return {
        ...state,
        registerPushNotifications: setUpdaterSending(
          state.registerPushNotifications,
        ),
      };

    case ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        registerPushNotifications: {
          ...setUpdaterSent(state.registerPushNotifications),
          ...action.payload.registrationInfo,
          shouldUpdatePushIdentifier: false,
        },
      };

    case ACTION_TYPE.REGISTER_PUSH_NOTIFICATIONS.FAILED:
      return {
        ...state,
        registerPushNotifications: {
          ...setUpdaterError(
            state.registerPushNotifications,
            action.payload.error,
          ),
          shouldUpdatePushIdentifier: true,
        },
      };

    case ACTION_TYPE.INIT_UNIQUE_IDENTIFIER:
      return {
        ...state,
        uniqueIdentifier: action.payload.uniqueIdentifier,
      };
    case ACTION_TYPE.LOCATE_ME.STARTED:
      return {
        ...state,
        tempLocation: setLoading(state.tempLocation),
      };
    case ACTION_TYPE.LOCATE_ME.SUCCESS:
      return {
        ...state,
        tempLocation: setDataLoaded(
          state.tempLocation,
          action.payload.userAddress,
        ),
        ...(action.payload.shouldSave && {
          userLocatedAddress: {
            ...action.payload.userAddress,
            timestamp: new Date(),
          },
        }),
      };
    case ACTION_TYPE.LOCATE_ME.FAILED:
      return {
        ...state,
        tempLocation: setError(
          state.tempLocation,
          _.get(action, "error") || _.get(action, "payload.error"),
        ),
      };
    case ACTION_TYPE.GET_CASH_CARD_DETAILS.STARTED:
      return {
        ...state,
        chargeCardDetails: setLoading(state.chargeCardDetails),
      };
    case ACTION_TYPE.GET_CASH_CARD_DETAILS.SUCCESS:
      return {
        ...state,
        chargeCardDetails: setDataLoaded(
          state.chargeCardDetails,
          action.payload,
        ),
      };
    case ACTION_TYPE.GET_CASH_CARD_DETAILS.FAILED:
      return {
        ...state,
        chargeCardDetails: setError(
          state.chargeCardDetails,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.RESET_HISTORY_CACHE:
      return { ...state, history: { loadTimestamp: null } };
    case ACTION_TYPE.RECHARGE_CARD.STARTED:
      return { ...state, rechargeCard: setUpdaterSending(state.rechargeCard) };
    case ACTION_TYPE.RECHARGE_CARD.SUCCESS:
      return { ...state, rechargeCard: setUpdaterSent(state.rechargeCard) };
    case ACTION_TYPE.RECHARGE_CARD.FAILED:
      return {
        ...state,
        rechargeCard: setUpdaterError(state.rechargeCard, action.payload.error),
      };
    case ACTION_TYPE.RECHARGE_CARD.RESET:
      return { ...state, rechargeCard: initUpdaterState() };
    case ACTION_TYPE.SHARE_GIFT_DESKTOP:
      return {
        ...state,
        shareGiftState: {
          ...state.shareGiftState,
          shareGiftDesktop: true,
        },
      };
    case ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.SUCCESS:
      if (action.payload) {
        return {
          ...state,
          currentPurchaseEvent: action.payload.purchaseEvent,
          shouldPollPendingPurchaseEvents: false,
          shouldPollStatusPurchaseEvent: true,
          ...getCouponBatchesSelectionInitialState(),
        };
      }
      return state;
    case ACTION_TYPE.GET_PENDING_PURCHASE_EVENTS.FAILED:
      return {
        ...state,
        currentPurchaseEvent: null,
        shouldPollPendingPurchaseEvents: true,
        shouldPollStatusPurchaseEvent: false,
      };
    case ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.STARTED:
      return {
        ...state,
        respondToPurchaseEventFromPOS: setUpdaterSending(
          state.respondToPurchaseEventFromPOS,
        ),
      };
    case ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.SUCCESS:
      return {
        ...state,
        respondToPurchaseEventFromPOS: setUpdaterSent(
          state.respondToPurchaseEventFromPOS,
        ),
        currentPurchaseEvent: null,
        shouldPollPendingPurchaseEvents: true,
        shouldPollStatusPurchaseEvent: false,
        purchaseEventBatchResponse: initLoader(),
        ...getCouponBatchesSelectionInitialState(),
      };
    case ACTION_TYPE.RESPOND_TO_PURCHASE_EVENT_FROM_POS.FAILED:
      return {
        ...state,
        respondToPurchaseEventFromPOS: setUpdaterError(
          state.respondToPurchaseEventFromPOS,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.SUCCESS:
      if (action.payload.purchaseEventId !== state.currentPurchaseEvent.id) {
        console.error(
          "purchase status id does not match current purchase event id",
        );
      }
      return {
        ...state,
        ...(action.payload.canceled && {
          currentPurchaseEvent: null,
          shouldPollPendingPurchaseEvents: true,
          shouldPollStatusPurchaseEvent: false,
        }),
      };
    case ACTION_TYPE.GET_PURCHASE_EVENT_STATUS.FAILED:
      return {
        ...state,
        currentPurchaseEvent: null,
        shouldPollPendingPurchaseEvents: true,
        shouldPollStatusPurchaseEvent: false,
      };
    case ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.STARTED:
      return {
        ...state,
        purchaseEventBatchResponse: setLoading(
          state.purchaseEventBatchResponse,
        ),
        ...getCouponBatchesSelectionInitialState(),
      };
    case ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.SUCCESS:
      return {
        ...state,
        purchaseEventBatchResponse: setDataLoaded(
          state.purchaseEventBatchResponse,
          action.payload.data,
        ),
      };
    case ACTION_TYPE.GET_COUPON_BATCHES_FOR_PURCHASE_EVENT.FAILED:
      return {
        ...state,
        purchaseEventBatchResponse: setError(
          state.purchaseEventBatchResponse,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.STARTED:
      return {
        ...state,
        privacyConsentDetails: setLoading(state.privacyConsentDetails),
      };
    case ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.SUCCESS:
      return {
        ...state,
        privacyConsentDetails: setDataLoaded(
          state.privacyConsentDetails,
          action.payload.data,
        ),
      };
    case ACTION_TYPE.GET_PRIVACY_CONSENT_DETAILS.FAILED:
      return {
        ...state,
        privacyConsentDetails: setError(
          state.privacyConsentDetails,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.SEND_PRIVACY_CONSENT.STARTED:
      return { ...state, consentState: setUpdaterSending(state.consentState) };
    case ACTION_TYPE.SEND_PRIVACY_CONSENT.SUCCESS:
      return _.get(action.payload, "data.status")
        ? {
          ...state,
          consentState: setUpdaterSent(
            state.consentState,
            action.payload.data,
          ),
        }
        : initialState;

    case ACTION_TYPE.SEND_PRIVACY_CONSENT.FAILED:
      return {
        ...state,
        consentState: setUpdaterError(state.consentState, action.payload.error),
      };
    case SET_USER_SELECTED_LOCATION_AND_SERVING_OPTION:
      return {
        ...state,
        ...(action.payload.userAddress && {
          userLocatedAddress: action.payload.userAddress,
          timestamp: new Date(),
        }),
      };
    case ACTION_TYPE.SET_COUPONS_CHECKED_FOR_PURCHASE_EVENT:
      const batches = _.get(
        state,
        "purchaseEventBatchResponse.data.batches",
        [],
      );
      const couponInstances = _.get(
        state,
        "purchaseEventBatchResponse.data.couponInstances",
        [],
      );

      const {
        couponsBatchIndex,
        couponsUncheckedByUser,
        couponsCheckedByUser,
      } = state;

      const couponBatchesState = reduceByCouponsChecked(
        action.payload.checked,
        {
          batches,
          couponInstances,
          couponsBatchIndex,
          couponsUncheckedByUser,
          couponsCheckedByUser,
        },
      );

      return {
        ...state,
        ...couponBatchesState,
      };

    case ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.STARTED:
      return { ...state, getGiftsUnseenAmountError: null };
    case ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.SUCCESS:
      return {
        ...state,
        amountOfGiftsUnseen: action.payload.amountOfGiftsUnseen,
      };
    case ACTION_TYPE.GET_UNSEEN_GIFTS_AMOUNT.FAILED:
      return { ...state, getGiftsUnseenAmountError: action.payload.error };
    case ACTION_TYPE.RESET_UNSEEN_GIFTS_AMOUNT:
      return { ...state, amountOfGiftsUnseen: 0 };
    case ACTION_TYPE.RESET_GIFT_CARD:
      return {
        ...state,
        giftCardState: initUpdaterState(),
        rechargeCard: initUpdaterState(),
      };
    case ACTION_TYPE.CREATE_GIFT_CARD.STARTED:
      return {
        ...state,
        giftCardState: setUpdaterSending(state.giftCardState),
      };
    case ACTION_TYPE.CREATE_GIFT_CARD.SUCCESS:
      return {
        ...state,
        giftCardState: setUpdaterSent(state.giftCardState, action.payload),
      };
    case ACTION_TYPE.CREATE_GIFT_CARD.ERROR:
      return {
        ...state,
        giftCardState: setUpdaterError(
          state.giftCardState,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.STARTED:
      return {
        ...state,
        externalGiftCards: setUpdaterSending(state.externalGiftCards),
      };
    case ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.SUCCESS:
      return {
        ...state,
        externalGiftCards: setUpdaterSent(
          state.externalGiftCards,
          action.payload,
        ),
      };
    case ACTION_TYPE.GET_EXTERNAL_GIFT_CARDS.ERROR:
      return {
        ...state,
        externalGiftCards: setUpdaterError(
          state.externalGiftCards,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.RESET_ADD_EXTERNAL_GIFT_CARD:
      return {
        ...state,
        addExternalGiftCardState: initUpdaterState(),
        removeExternalGiftCardState: initUpdaterState(),
      };
    case ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.STARTED:
      return {
        ...state,
        addExternalGiftCardState: setUpdaterSending(
          state.addExternalGiftCardState,
        ),
      };
    case ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.SUCCESS:
      return {
        ...state,
        addExternalGiftCardState: setUpdaterSent(
          state.addExternalGiftCardState,
          action.payload,
        ),
        signupState: {
          ...state.signupState,
          signupGiftCardDetails: null,
        },
      };
    case ACTION_TYPE.ADD_EXTERNAL_GIFT_CARD.ERROR:
      return {
        ...state,
        addExternalGiftCardState: setUpdaterError(
          state.addExternalGiftCardState,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.STARTED:
      return {
        ...state,
        removeExternalGiftCardState: setUpdaterSending(
          state.removeExternalGiftCardState,
        ),
      };
    case ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.SUCCESS:
      return {
        ...state,
        removeExternalGiftCardState: setUpdaterSent(
          state.removeExternalGiftCardState,
          action.payload,
        ),
      };
    case ACTION_TYPE.REMOVE_EXTERNAL_GIFT_CARD.ERROR:
      return {
        ...state,
        removeExternalGiftCardState: setUpdaterError(
          state.removeExternalGiftCardState,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.LOAD_LOYALTY_PROFILE.STARTED:
      return { ...state, loyaltyProfile: setLoading(state.loyaltyProfile) };
    case ACTION_TYPE.LOAD_LOYALTY_PROFILE.SUCCESS:
      return {
        ...state,
        loyaltyProfile: setDataLoaded(
          state.loyaltyProfile,
          action.payload.data,
        ),
      };
    case ACTION_TYPE.LOAD_LOYALTY_PROFILE.FAILED:
      return {
        ...state,
        loyaltyProfile: setError(state.loyaltyProfile, action.payload.error),
      };
    case ACTION_TYPE.TRADE_POINTS_FOR_COUPON.STARTED:
      return {
        ...state,
        tradingPointsForCoupon: true,
        lastPointTradeCoupon: action.payload.pointsBankCoupon,
        displayTradeNotice: true,
      };
    case ACTION_TYPE.TRADE_POINTS_FOR_COUPON.SUCCESS:
      return {
        ...state,
        tradingPointsForCoupon: false,
        ...(!_.isEmpty(action.payload.loyaltyProfile) && {
          loyaltyProfile: setDataLoaded(
            state.loyaltyProfile,
            action.payload.loyaltyProfile,
          ),
        }),
      };

    case ACTION_TYPE.TRADE_POINTS_FOR_COUPON.FAILED:
      return {
        ...state,
        tradingPointsForCoupon: false,
        tradePointsError: true,
      };
    case ACTION_TYPE.TRADE_POINTS_FOR_COUPON.RESET:
      return {
        ...state,
        tradingPointsForCoupon: false,
        tradePointsError: false,
        displayTradeNotice: false,
      };
    case ACTION_TYPE.LOAD_WAITING_ORDERS.STARTED:
      return {
        ...state,
        waitingOrders: setLoading(state.waitingOrders),
      };
    case ACTION_TYPE.LOAD_WAITING_ORDERS.SUCCESS:
      return {
        ...state,
        waitingOrders: setDataLoaded(state.waitingOrders, action.payload.data),
      };
    case ACTION_TYPE.LOAD_WAITING_ORDERS.FAILED:
      return {
        ...state,
        waitingOrders: setError(state.waitingOrders, action.payload.error),
      };
    case ACTION_TYPE.DELETE_ACCOUNT.STARTED:
      return {
        ...state,
        deleteAccount: setUpdaterSending(state.deleteAccount),
      };

    case ACTION_TYPE.DELETE_ACCOUNT.SUCCESS:
      return {
        ...state,
        deleteAccount: setUpdaterSent(state.deleteAccount, action.payload),
      };
    case ACTION_TYPE.DELETE_ACCOUNT.FAILED:
      return {
        ...state,
        deleteAccount: setUpdaterError(
          state.deleteAccount,
          action.payload.error,
        ),
      };
    case ACTION_TYPE.SAVE_SUBSCRIPTION.STARTED:
      return {
        ...state,
        subscription: {
          sending: true,
          sent: false,
          error: null,
        }
      };
    case ACTION_TYPE.SAVE_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        subscription: {
          sending: false,
          sent: true,
          error: null,
        }
      };
    case ACTION_TYPE.SAVE_SUBSCRIPTION.FAILED:
      return {
        ...state,
        subscription: {
          sending: false,
          sent: false,
          error: action.payload.error,
        }
      };
    case ACTION_TYPE.SAVE_SUBSCRIPTION.RESET:
      return {
        ...state,
        subscription: initUpdaterState(),
      };
    default:
      return state;
  }
};
