import classnames from "classnames";
import _ from "lodash";
import React from "react";
import * as styles from "./CardCheckboxGroup.module.scss";

class CheckboxGroup extends React.Component {
  // not handling limit
  constructor(props) {
    super(props);
    const { children, checked = [], limit } = props;

    const checkedIndices = _.filter(
      _.map(checked, (value) =>
        children.findIndex((c) => c.props.value === value),
      ),
      (index) => index !== -1,
    );

    this.state = { checkedIndices, disabled: checkedIndices.length >= limit };
  }

  get values() {
    const { children } = this.props;

    _.map(this.state.checkedIndices, (index) => children[index].props.value) ||
      [];
  }

  // This is the case to handle late arriving props,
  // and set the state according to the value
  // as long as it's not disabled
  // not handling limit
  componentDidUpdate(prevProps) {
    const { checked, children } = this.props;
    if (checked) {
      const checkedIndices = _.filter(
        _.map(checked, (value) =>
          children.findIndex((c) => c.props.value === value),
        ),
        (index) => index !== -1,
      );
      if (!_.isEqual(checkedIndices, this.state.checkedIndices)) {
        this.setState({ checkedIndices });
      }
    }
  }

  onChange = (index) => {
    const { onChange, children, limit } = this.props;
    const { checkedIndices } = this.state;
    const child = children[index];
    if (!child) return;

    if (limit === 1) {
      this.setState({ checkedIndices: [index] }, () => {
        onChange &&
          onChange(
            _.map(
              this.state.checkedIndices,
              (index) => children[index].props.value,
            ) || [],
          );
      });
    }

    if (
      this.state.checkedIndices.length >= limit &&
      !_.includes(this.state.checkedIndices, index)
    )
      return;

    const nextCheckedIndices = _.xor(this.state.checkedIndices, [index]);

    this.setState({ checkedIndices: nextCheckedIndices }, () => {
      onChange &&
        onChange(
          _.map(
            this.state.checkedIndices,
            (index) => children[index].props.value,
          ) || [],
        );
    });
  };

  renderChild = (child, index, checked, disabled) => {
    const {
      children,
      horizontal,
      limit,
      appStyles: { rtl } = {},
      padding = 10,
      wrap,
    } = this.props;
    const { checkedIndices } = this.state;

    return React.cloneElement(child, {
      horizontal,
      index,
      padding,
      disabled:
        disabled || (limit > 1 && !checked && checkedIndices.length >= limit),
      checked,
      rtl,
      key: index,
      last: index === children.length - 1,
      onChange: this.onChange,
      ...child.props,
    });
  };

  render() {
    const { checkedIndices } = this.state;
    const {
      horizontal,
      limit,
      children,
      wrap,
      appStyles,
      disabled,
      ...props
    } = this.props;
    const style = horizontal
      ? {
          display: "inline-flex",
          justifyContent: "space-around",
          width: "100%",
        }
      : {};
    return (
      <div
        style={style}
        {...props}
        className={classnames(styles.CardCheckboxGroup, wrap && styles.Wrap)}
      >
        {_.map(_.filter(children), (child, index) =>
          this.renderChild(
            child,
            index,
            _.includes(checkedIndices, index),
            _.includes(disabled, index),
          ),
        )}
      </div>
    );
  }
}

class Checkbox extends React.Component {
  getStyles = () => {
    const {
      horizontal,
      last,
      padding = 10,
      rootColor = "currentColor",
      pointColor = "#8CB9FD",
      disabled,
      disabledColor = "#e1e1e1",
      label,
      outerBorderColor = "#E0E0E0",
    } = this.props;

    return {
      root: {
        transition: "all 0.2s ease-in-out",
        cursor: disabled ? "not-allowed" : "pointer",
        color: disabled ? disabledColor : rootColor,
        borderColor: disabled ? disabledColor : outerBorderColor,
        padding: padding,
        marginBottom: horizontal ? 0 : padding || 10,
      },
      label: {
        transition: "all 0.2s ease-in-out",
        color: pointColor,
        borderStyle: "none",
        padding: padding / 2,
        marginBottom: horizontal ? 0 : padding / 2,
        textAlign: "center",
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: outerBorderColor,
        color: pointColor,
        fontWeight: "bold",
      },
    };
  };

  onClick = () => {
    const { onChange, checked, index, disabled } = this.props;
    !disabled && onChange && onChange(index);
  };

  renderChild = (child, checked) => {
    const {
      selectedChildrenClassName,
      pointColor,
      rtl,
      outerBorderColor,
    } = this.props;
    const style = { color: pointColor };

    return React.cloneElement(child, {
      checked,
      rtl,
      outerBorderColor,
      className: classnames(
        checked && selectedChildrenClassName,
        child.props.className,
      ),
      style: style,
    });
  };

  render() {
    const {
      checked,
      iconSize,
      iconInnerSize,
      rootColor,
      pointColor,
      children,
      disabled,
      disabledColor,
      outerBorderColor = "#777",
      padding,
      label,
      rtl,
      horizontal,
      vertical,
      selectedChildrenClassName,
      classNames,
    } = this.props;

    const style = this.getStyles();
    const buttonStyle = _.assign({}, style.root, checked ? style.checked : {});
    const labelStyle = _.assign({}, style.root, style.label);

    return (
      <div
        style={buttonStyle}
        role="checkbox"
        aria-checked={Boolean(checked)}
        onClick={this.onClick}
        className={classnames(styles.CardCheckboxOptionWrapper, classNames)}
      >
        <div
          style={
            horizontal
              ? {
                  display: "flex",
                  flexDirection: "column-reverse",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "space-between",
                }
              : { display: "inline-flex", width: "100%" }
          }
        >
          <CheckboxIcon
            rtl={rtl}
            size={iconSize}
            innerSize={iconInnerSize}
            checked={checked}
            rootColor={rootColor}
            pointColor={pointColor}
            disabled={disabled}
            disabledColor={disabledColor}
            outerBorderColor={outerBorderColor}
            marginRight={padding}
          />
          {horizontal && label && (
            <div style={labelStyle}>
              <div>{label}</div>
            </div>
          )}
          <div
            style={{
              alignItems: vertical ? "unset" : "center",
              display: "flex",
              justifyContent: "space-between",
              ...(vertical && { flexDirection: "column" }),
              ...(!horizontal && { flexGrow: 1, overflowWrap: "anywhere" }),
            }}
          >
            {horizontal ? this.renderChild(children, checked) : children}
          </div>
        </div>

        {!horizontal && label && (
          <div style={labelStyle}>
            <div>{label}</div>
          </div>
        )}
      </div>
    );
  }
}

class CheckboxIcon extends React.Component {
  getStyles = () => {
    const {
      size = 10,
      innerSize = 10,
      rootColor = "#9E9E9E",
      pointColor = "#8CB9FD",
      disabled,
      backgroundColor = "#FFF",
      padding = 4,
      outerBorderColor,
      disabledColor = "#e1e1e1",
      rtl,
      marginRight = 0,
    } = this.props;

    return {
      root: {
        transition: "all 0.2s ease-in-out",
        width: size,
        height: size,
        padding,
        backgroundColor,
        borderWidth: 1,
        borderRadius: 2,
        borderStyle: "solid",
        flexShrink: 0,
        borderColor: disabled ? disabledColor : outerBorderColor,
        ...(!rtl && { marginRight }),
        ...(rtl && { marginLeft: marginRight }),
      },
      checked: {
        transition: "all 0.2s ease-in-out",
        borderColor: outerBorderColor,
      },
      inner: {
        transition: "all 0.2s ease-in-out",
        width: innerSize,
        height: innerSize,
        background: pointColor,
        borderRadius: 2,
      },
    };
  };

  render() {
    const { checked } = this.props;
    const style = this.getStyles();
    const iconStyle = _.assign(style.root, checked ? style.checked : {});
    return (
      <div style={iconStyle}>{checked && <div style={style.inner} />}</div>
    );
  }
}

export const CardCheckboxGroup = (props) => <CheckboxGroup {...props} />;

export const CardCheckboxOption = (props) => <Checkbox {...props} />;
