import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react'
import forEach from "lodash/forEach";
import initReactFastclick from "react-fastclick";
import XHR from "i18next-xhr-backend";
import Backend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import i18n from "./src/i18n";
import { withPrefix } from "gatsby";
import wrapPage from "./wrap-page";
import { CacheProvider } from "@emotion/react";

// window.isNativeApp = true;

const unregisterSW = () => {
  if (
    navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistrations
  ) {
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) =>
        forEach(registrations, (registration) => registration.unregister()),
      );
  }
};

unregisterSW();

import configureStore from "./src/store/configureStore";
import { createMuiCache } from "./src/components/AppContainer/theme-cache";

const initialState = {};

export const wrapRootElement = ({ element }) => {
  const { store, persistor } = configureStore(initialState, { loadLocalStorage: true });
  const muiCache = createMuiCache();
  
  const ConnectedRootElement = (
    <CacheProvider value={muiCache}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {element}
        </PersistGate>
      </Provider>
    </CacheProvider>
  );
  return ConnectedRootElement;
};

export const onClientEntry = async () => {
  i18n.use(Backend).init({
    backend: {
      backends: [
        LocalStorageBackend, // primary
        XHR, // fallback
      ],
      backendOptions: [
        {
          expirationTime: 5 * 60 * 1000,
          prefix: `${process.env.GATSBY_BUSINESS_ID}_`,
        },
        { loadPath: withPrefix("locales/{{lng}}/{{ns}}.json") },
      ],
    },
  });
  initReactFastclick();

  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const wrapPageElement = wrapPage;

export const shouldUpdateScroll = ({
  routerProps: {
    location: { pathname },
  },
}) => {
  // should not scroll to top on order page in order to support scroll actions on page
  if (pathname.includes("order")) {
    console.log("no scroll top");
    return false;
  }
  // default scroll behavior
  return true;
};
