import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "../AppContainer/mui-theme";
import { useInView } from "react-intersection-observer";
import classNames from "classnames";
import { getSrcSet } from "../../utils/media";

export default ({
  mediaType,
  mediaKey,
  sizes,
  imagePreview,
  alt = "",
  wrapperStyle,
  imgStyle,
  className,
  noAnimation,
}) => {
  const { classes } = useStyles();
  const { ref, inView, entry } = useInView({
    threshold: 0,
    rootMargin: "400px 0px 400px 0px",
    root: null,
  });

  const [imageLoaded, setImageLoaded] = useState(noAnimation);

  const [imageLoadStartTime, setImageLoadStartTime] = useState();
  const [animation, setAnimation] = useState(
    noAnimation ? { transition: "none" } : undefined,
  );
  useEffect(() => {
    if (inView && !imageLoadStartTime) {
      setImageLoadStartTime(new Date().getTime());
    }
  }, [inView, imageLoadStartTime]);

  useEffect(() => {
    if (!imageLoadStartTime) {
      return;
    }

    if (new Date().getTime() < imageLoadStartTime + 20) {
      setAnimation({ transition: "opacity 0.1s ease 0s" });
    } else {
      setAnimation({ transition: "opacity 0.5s ease 0s" });
    }
  }, [imageLoaded, imageLoadStartTime]);

  // useEffect(() => {
  //   const img = new Image();
  //   img.srcSet = srcSet;
  //   console.log("complete", img.complete, img);
  // }, []);

  const handleImageLoaded = useCallback((e) => {
    setImageLoaded(true);
  }, []);

  const handleImageLoadingFailed = useCallback((e) => {
    console.log("image failed to load, not handled.");
  }, []);

  const srcSet = getSrcSet(mediaType, mediaKey);
  const srcSetFallback = getSrcSet(mediaType, mediaKey, true);

  const imageParams = {
    sizes,
    alt,
    style: {
      ...imgStyle,
      ...(imageLoaded && animation && { opacity: 1 }),
      ...animation,
    },
    className: classes.fullSize,
    onLoad: handleImageLoaded,
    onError: handleImageLoadingFailed,
  };

  return (
    <div
      ref={ref}
      className={classNames(classes.root, className)}
      style={wrapperStyle}
    >
      {!noAnimation && (
        <img
          src={imagePreview}
          alt={alt}
          className={classes.preview}
          style={{
            ...imgStyle,
            ...(imageLoaded && animation && { opacity: 0 }),
            ...animation,
          }}
        />
      )}
      {(inView || imageLoaded) && (
        <picture>
          <source srcSet={srcSet} {...imageParams} type="image/webp" />
          <source srcSet={srcSetFallback} {...imageParams} type="image/jpeg" />
          <img srcSet={srcSetFallback} {...imageParams} />
        </picture>
      )}
    </div>
  );
};

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      position: "relative",
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    preview: {
      opacity: 1,
      filter: "blur(12px)",
      objectFit: "cover",
      objectPosition: "center center",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      display: "inline-block",
    },
    fullSize: {
      opacity: 0,
      objectFit: "cover",
      objectPosition: "center center",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
    },
  };
});
