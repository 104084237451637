import * as ACTION_TYPE from "./constants";
import { LOCATION_CHANGE } from "../../utils/constants";

const initialState = {
  isSSR: true,
  isMobile: false,
  keyboardOpen: false,
  checkoutOpen: false,
  authModalOpen: false,
  mobileAuthViewOpen: false,
  loginType: null,
  granted: false,
  kioskLocationId: null,
  userRequestedCheckoutClosedOnDesktop: false,
  inStoreSignupBranchId: null,
  google: null,
  groupReferralUrl: null,
  editorAppStyles: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REDUX_STORAGE_LOAD":
      return {
        ...state,
        isSSR: false,
        google: null,
        keyboardOpen: false,
        authModalOpen: false,
        mobileAuthViewOpen: false,
        editorAppStyles: null,
        editorPromotions: null,
      };
    case LOCATION_CHANGE:
      return { ...state, keyboardOpen: false };
    case ACTION_TYPE.GOOGLE_LOADED:
      return { ...state, google: action.payload.google };
    case ACTION_TYPE.SET_KEYBOARD_STATUS:
      return { ...state, keyboardOpen: action.payload.keyboardOpen };
    case ACTION_TYPE.TOGGLE_CHECKOUT_VIEW:
      return {
        ...state,
        userRequestedCheckoutClosedOnDesktop: !state.userRequestedCheckoutClosedOnDesktop,
      };
    case ACTION_TYPE.OPEN_CHECKOUT_VIEW:
      return { ...state, checkoutOpen: true };
    case ACTION_TYPE.CLOSE_CHECKOUT_VIEW:
      return { ...state, checkoutOpen: false };
    case ACTION_TYPE.OPEN_AUTH_MODAL:
      return {
        ...state,
        authModalOpen: true,
        loginType: action.payload.loginType,
      };
    case ACTION_TYPE.CLOSE_AUTH_VIEW:
      return { ...state, authModalOpen: false, loginType: null };
    case ACTION_TYPE.CLOSE_AUTH_MODAL:
      return { ...state, authModalOpen: false, mobileAuthViewOpen: false };
    case ACTION_TYPE.OPEN_MOBILE_AUTH_VIEW:
      return {
        ...state,
        mobileAuthViewOpen: true,
        loginType: action.payload.loginType,
      };
    case ACTION_TYPE.CLOSE_MOBILE_AUTH_VIEW:
      return { ...state, mobileAuthViewOpen: false, loginType: null };
    case ACTION_TYPE.SET_CAMERA_PERMISSIONS_ANDROID:
      return { ...state, granted: action.payload.granted };
    case ACTION_TYPE.SET_KIOSK_LOCATION_ID:
      return { ...state, kioskLocationId: action.payload.kioskLocationId };
    case ACTION_TYPE.SET_IN_STORE_SIGNUP_BRANCHID:
      return { ...state, inStoreSignupBranchId: action.payload.branchId };
    case ACTION_TYPE.SET_GROUP_APP_MODE_PARAMS:
      return { ...state, groupReferralUrl: action.payload.groupReferralUrl };
    case ACTION_TYPE.SET_EDITOR_APP_STYLES:
      return { ...state, editorAppStyles: action.payload.appStyles };
    case ACTION_TYPE.RESET_EDITOR_APP_STYLES:
      return { ...state, editorAppStyles: null };
    case ACTION_TYPE.SET_EDITOR_PROMOTIONS:
      return { ...state, editorPromotions: action.payload.promotions };
    case ACTION_TYPE.RESET_EDITOR_PROMOTIONS:
      return { ...state, editorPromotions: null };
    case ACTION_TYPE.SET_IS_MOBILE_VIEW:
      return { ...state, isMobile: action.payload.isMobile };
    default:
      return state;
  }
};
