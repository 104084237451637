import * as ACTION_TYPE from './constants';
import _ from 'lodash';
import { addItems as addItemsToOrder } from '../order/actions';
import { tradeBankPointsForCoupon } from '../user/actions';
import { isPointBankCoupon } from '../../utils/gifts';

export const resetGiftRedeem = () => ({ type: ACTION_TYPE.RESET_GIFT_REDEEM });

export const selectGift = (gift, menuData) => (dispatch, getState) => {
  const { order } = getState();

  dispatch({
    type: ACTION_TYPE.SELECT_GIFT,
    // branchId can be null if the user didn't select a branch (location) to order from yet.
    payload: { gift, selectedBranchId: order.branchId, selectedServingOptionType: order.servingOptionType, menuData },
  });
};

export const selectDeal = (deal, menuData) => (dispatch, getState) => {
  const { order } = getState();
  if (!order.branchId) {
    console.warn('branch not selected yet, not starting deal redeem');
    return;
  }
  dispatch({
    type: ACTION_TYPE.SELECT_DEAL,
    payload: { deal, selectedBranchId: order.branchId, menuData },
  });
};

export const saveGiftStep = item => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPE.SAVE_GIFT_STEP,
    payload: { item },
  });

  const { giftRedeem } = getState();
  if (giftRedeem.steps.length - 1 === giftRedeem.currentStepIndex) {
    const items = _.map(_.filter(giftRedeem.steps, 'context.item'), 'context.item');
    dispatch(addItemsToOrder(items));
    if (isPointBankCoupon(giftRedeem.selectedGift)) {
      dispatch(tradeBankPointsForCoupon(giftRedeem.selectedGift));
    }
    dispatch(resetGiftRedeem);
  }
};

export const saveSelectedGiftStep = (item, menuData) => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPE.SAVE_GIFT_STEP,
    payload: { selectedItem: item, menuData },
  });
};
