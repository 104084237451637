const get = require("../utils/get");
const INTERNAL_API_ROOT =
  process.env.GATSBY_APP_ENV === "production"
    ? "https://gqlapi.appfront.ai/internal"
    : "https://gqlsandbox.appfront.ai/internal";

const authHeaders = {
  Authorization: process.env.INTERNAL_APPFRONT_API_JWT_SECRET,
};

module.exports = {
  mobile: {
    getWellKnown: (businessId) =>
      get(
        `${INTERNAL_API_ROOT}/businesses/${businessId}/mobile-app/well-known`,
        authHeaders,
      ),
  },
  app: {
    getAppStyles: (businessId) =>
      get(
        `${INTERNAL_API_ROOT}/businesses/${businessId}/app-styles`,
        authHeaders,
      ),

    getPromotions: (businessId) =>
      get(
        `${INTERNAL_API_ROOT}/businesses/${businessId}/promotions?includeGroups=true`,
        authHeaders,
      ),
  },
};
