import React from "react";
import Markdown from "react-markdown";
import RemarkBreaksPlugin from "remark-breaks";
import ExternalLink from "../ExternalLink";

export default ({ children }) => {
  if (typeof children === "string") {
    const strippedEscape = children.replace(/\\n/g, "\n");
    return (
      <Markdown
        parserOptions={{ commonmark: true }}
        remarkPlugins={[RemarkBreaksPlugin]}
        linkTarget="_blank"
        renderers={{
          link: ExternalLink,
        }}
      >
        {strippedEscape}
      </Markdown>
    );
  }

  return "Rich text should be markdown (MD) formatted or plain text";
};
