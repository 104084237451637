import { filter, get, intersection, isEmpty, map } from "lodash";
import { getAppStyles } from "./app-styles";
import moment from "../../utils/moment-timezone-with-data";


const filterPromotionsByGroups = (promotionsToFilter, user) => {
  if (user && user.loggedIn){
    const groupIds = get(user, "loyaltyProfile.data.groupIds") || [];
    return filter(promotionsToFilter, promotion => {
      return (!get(promotion, "groups") || 
          isEmpty(get(promotion, "groups")) || 
          !isEmpty(intersection(groupIds, get(promotion, "groups"))));
    }); 
  }

  return filter(promotionsToFilter, promotion => !get(promotion, "groups") || isEmpty(get(promotion, "groups")));
}

const nowBetweenDates = (from, to) => {
  const now = moment();
  return now.isAfter(from) && now.isBefore(to);
}

const filterPromotionsByScheduling = (promotionsToFilter) => {
  return filter(promotionsToFilter, promotion => {
    return !get(promotion, "schedule.enabled") || nowBetweenDates(get(promotion, "schedule.fromDate"), get(promotion, "schedule.toDate"));
  });
}

export const getPromotions = (state, props) => {
  const homepagePromotions = get(
    getAppStyles(state, props),
    "homepagePromotions",
  );
  const promotions = get(props, "pageContext.promotions");
  const editorPromotions = get(state, "app.editorPromotions");

  const { user } = state;

  const promotionsToFilter = editorPromotions || promotions || []

  return filterPromotionsByScheduling(filterPromotionsByGroups(promotionsToFilter, user));
};
