import classnames from "classnames";
import { withPrefix } from "gatsby";
import compact from "lodash/compact";
import React, { useMemo } from "react";
import { Link, navigateTo } from "../../utils/location";
import { getAppMedia, mediaTypes } from "../../utils/media";
import ChevronLeftIcon from "../icons/ChevronLeft.svg";
import CloseIcon from "../icons/Close.svg";
import Image from "../image";
import HeaderTitle from "./HeaderTitle";
import * as styles from "./index.module.scss";

const AppLogo = ({ withLink, display = true, appStyles, brandTo, img }) => {
  const { Logo = {}, DesktopLogo = {} } = appStyles;
  const logoStyles = {
    ...(Logo.height && { height: Logo.height }),
    ...(Logo.width && { width: Logo.width }),
  };

  const desktopLogoStyles = {
    ...(DesktopLogo.height && { height: DesktopLogo.height }),
    ...(DesktopLogo.width && { width: DesktopLogo.width }),
  };

  const { imagePreview: logoPreview, imageKey: logoKey } = useMemo(
    () => getAppMedia(mediaTypes.logo, appStyles) || {},
    [appStyles],
  );

  const Element = withLink ? Link : "div";
  const extraProps = withLink ? { to: brandTo } : null;
  const MissingBrandLogo = () => <strong>Logo</strong>;
  return compact([
    appStyles.DesktopLogo && (
      <Element
        key="wrapper"
        className={classnames(styles.BrandLink, styles.HideOnMobile)}
        style={{ ...desktopLogoStyles, ...(!display && { display: "none" }) }}
        {...extraProps}
      >
        {logoKey ? (
          <Image
            className={classnames(styles.Logo, styles.GatsbyImageOuterWrapper)}
            mediaKey={logoKey}
            mediaType={mediaTypes.logo}
            imagePreview={logoPreview}
            sizes="60px"
            wrapperStyle={desktopLogoStyles}
          />
        ) : (
          <MissingBrandLogo />
        )}
      </Element>
    ),
    <Element
      key="mobileWrapper"
      className={classnames(
        styles.BrandLink,
        appStyles.DesktopLogo && styles.HideOnDesktop,
      )}
      style={{ ...logoStyles, ...(!display && { display: "none" }) }}
      {...extraProps}
    >
      {logoKey ? (
        <Image
          className={classnames(styles.Logo, styles.GatsbyImageOuterWrapper)}
          mediaKey={logoKey}
          mediaType={mediaTypes.logo}
          imagePreview={logoPreview}
          sizes="60px"
          wrapperStyle={logoStyles}
        />
      ) : (
        <MissingBrandLogo />
      )}
    </Element>,
  ]);
};

export default ({
  T,
  title,
  backPath,
  backFunction,
  children,
  img,
  brandTo = "/",
  appStyles,
  showOnlyClose,
  closeFunction,
  onCloseTarget,
  relativePosition,
  showLogo,
  mobileAuthViewOpen,
  closeMobileAuthView,
  hasCheckoutIcon,
  panelOpen,
  noShadow,
  extraHeader: ExtraHeader,
}) => {
  const debouncedNavigateTo = navigateTo; //, 500);

  const handleLinkClick = (path) => (e) => {
    e.preventDefault();
    debouncedNavigateTo(path);
  };

  const {
    Header: {
      color,
      backgroundColor,
      fontSize,
      fontFamily,
      textTransform,
      fontWeight = "unset",
      letterSpacing = "unset",
      backgroundImage,
    } = {},
    cardBackgroundColor,
    bigDesktopHeader,
    ExtraHeader: ExtraHeaderStyle,
  } = appStyles;

  return (
    <div
      className={classnames(
        styles.Header,
        appStyles.rtl && styles.RTL,
        relativePosition && styles.RelativePosition,
        panelOpen && hasCheckoutIcon && styles.PanelOpen,
        noShadow && styles.NoShadow,
        bigDesktopHeader && styles.BigDesktopHeader,
      )}
    >
      <div
        className={classnames(
          styles.HeaderLine,
          appStyles.rtl && styles.RTL,
        )}
        style={{
          color,
          ...(backgroundImage
            ? {
                background: `url(${withPrefix(
                  backgroundImage,
                )}) center top no-repeat ${appStyles.headerBackgroundColor}`,
              }
            : { background: appStyles.headerBackgroundColor }),
        }}
      >
        {backPath && (
          <a
            onClick={handleLinkClick(backPath)}
            className={styles.BackPath}
            style={{ color }}
            role="button"
            aria-label={T("Back")}
          >
            <ChevronLeftIcon style={{ color }} />
            <span className={styles.NonMobileBackButtonText}>{T("Back")}</span>
          </a>
        )}
        {backFunction && (
          <a
            onClick={backFunction}
            className={styles.BackPath}
            style={{ color }}
            role="button"
            aria-label={T("Back")}
          >
            <ChevronLeftIcon style={{ color }} />
            <span className={styles.NonMobileBackButtonText}>{T("Back")}</span>
          </a>
        )}
        {title && showLogo ? (
          <React.Fragment>
            <AppLogo appStyles={appStyles} brandTo={brandTo} img={img} />

            <HeaderTitle
              narrow={appStyles.noIcons}
              color={color}
              fontFamily={fontFamily}
              fontSize={fontSize}
              textTransform={textTransform}
              fontWeight={fontWeight}
              letterSpacing={letterSpacing}
            >
              {title}
            </HeaderTitle>
          </React.Fragment>
        ) : title ? (
          <HeaderTitle
            narrow={appStyles.noIcons}
            color={color}
            fontFamily={fontFamily}
            fontSize={fontSize}
            textTransform={textTransform}
            fontWeight={fontWeight}
            letterSpacing={letterSpacing}
          >
            {title}
          </HeaderTitle>
        ) : showLogo ? (
          <AppLogo appStyles={appStyles} brandTo={brandTo} img={img} />
        ) : null}
        {!backPath && (
          <AppLogo
            withLink
            display={!(title || showOnlyClose)}
            appStyles={appStyles}
            brandTo={brandTo}
            img={img}
          />
        )}

        {!showOnlyClose && children}
        {showOnlyClose && <span />}
        {showOnlyClose &&
          (mobileAuthViewOpen ? (
            <span
              onClick={closeMobileAuthView}
              className={styles.CloseButton}
              style={{ color }}
              role="button"
              aria-label={T("Close")}
            >
              <CloseIcon style={{ color }} />
            </span>
          ) : closeFunction ? (
            <span
              onClick={closeFunction}
              className={styles.CloseButton}
              style={{ color }}
              role="button"
              aria-label={T("Close")}
            >
              <CloseIcon style={{ color }} />
            </span>
          ) : (
            <Link
              to={onCloseTarget}
              className={styles.CloseButton}
              style={{ color }}
              role="button"
              aria-label={T("Close")}
            >
              <CloseIcon style={{ color }} />
            </Link>
          ))}
      </div>
      {ExtraHeader && (
        <ExtraHeader
          className={styles.ExtraHeader}
          style={{
            backgroundColor: cardBackgroundColor,
            ...ExtraHeaderStyle,
          }}
        />
      )}
    </div>
  );
};
